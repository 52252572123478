<template>
  <div v-if="respData">
    <b-row v-if="$ability.can('read', this.$route.meta.resource)" class="match-height">
      <b-col md="12">
        <b-card :title="$t('Payment')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">{{ $t('List') }}</b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Transfer') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox :checked="respData['payment'] != null
                          ? respData['payment']['transfer']['status']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('payment.transfer.status', $event)" />
                        <b-card-text>
                          {{
                            respData["payment"] != null
                            ? respData["payment"]["transfer"]["status"] == true
                              ? "เปิด (โอนจำนวนเต็ม)"
                              : "ปิด (โอนจำนวนเต็ม)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                          <b-button v-if="respData['payment']['transfer']['status'] === true" size="sm" variant="primary"
                            @click="settingPayment('transfer')">
                            <span class="mr-25 align-middle"> {{ $t("Setting") }}</span>
                          </b-button>
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('QRcode') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox :checked="respData['payment'] != null
                          ? respData['payment']['qrcode']['status']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('payment.qrcode.status', $event)" />
                        <b-card-text>
                          {{
                            respData["payment"] != null
                            ? respData["payment"]["qrcode"]["status"] == true
                              ? "เปิด (โอนผ่านQRcode)"
                              : "ปิด (โอนผ่านQRcode)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                          <b-button v-if="respData['payment']['qrcode']['status'] === true" size="sm" variant="primary"
                            @click="settingPayment('qrcode')">
                            <span class="mr-25 align-middle"> {{ $t("Setting") }}</span>
                          </b-button>
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Cash') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox :checked="respData['payment'] != null
                          ? respData['payment']['cash']['status']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('payment.cash.status', $event)" />
                        <b-card-text>
                          {{
                            respData["payment"] != null
                            ? respData["payment"]["cash"]["status"] == true
                              ? "เปิด (ชำระปลายทาง)"
                              : "ปิด (ชำระปลายทาง)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-card-text>
                      </b-row>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Omise')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">{{ $t('List') }}</b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Status') }} : </b-td>
                    <b-td>
                      <b-row>

                        <b-form-checkbox :checked="respData['omise'] != null
                          ? respData['omise']['status']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('omise.status', $event)" />
                        <b-card-text>
                          {{
                            respData["omise"] != null
                            ? respData["omise"]["status"] == true
                              ? "เปิด (Omise)"
                              : "ปิด (Omise)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-card-text>
                      </b-row>

                    </b-td>
                  </b-tr>

                  <b-tr v-if="respData['omise']['status']">
                    <b-td>{{ $t('Public Key') }} : </b-td>
                    <b-td>
                      <b-form-input id="input-lg-omise-public-key" v-model="omisePublicKey"
                        placeholder="omisePublicKey" />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="respData['omise']['status']">
                    <b-td>{{ $t('Secret Key') }} : </b-td>
                    <b-td>
                      <b-form-input id="input-lg-omise-secret-key" v-model="omiseSecretKey"
                        placeholder="omiseSecretKey" />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="respData['omise']['status']">
                    <b-td>{{ $t('Activate') }} : </b-td>
                    <b-td>
                      <b-card-text>
                        <b-badge :variant="respData['omise'] != null
                          ? respData['omise']['activate'] == true
                            ? 'success'
                            : 'info'
                          : 'dark'">
                          {{
                            respData["omise"]
                            != null
                            ?
                            respData['omise']['activate'] ==
                              true
                              ?
                              "ยืนยันแล้ว"
                              :
                              "ยังไม่ยืนยัน"
                            :
                            "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-badge>
                        <b-button class="ml-2" size="sm" variant="primary">
                          <span> {{ $t("Activate") }}</span>
                          <feather-icon icon="CheckIcon" size="12" />
                        </b-button>
                      </b-card-text>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button size="sm" variant="primary" @click="inputChange('omise')">
                <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
                <feather-icon icon="SaveIcon" size="12" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card :title="$t('Paypal')">
          <b-row>
            <b-col cols="12">
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">{{ $t('List') }}</b-td>
                    <b-td>{{ $t('Setting') }}</b-td>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Status') }} : </b-td>
                    <b-td>
                      <b-row>

                        <b-form-checkbox :checked="respData['payment'] != null
                          ? respData['payment']['paypal']['status']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('payment.paypal.status', $event)" />
                        <b-card-text>
                          {{
                            respData["payment"] != null
                            ? respData['payment']['paypal']['status'] == true
                              ? "เปิด (Paypal)"
                              : "ปิด (Paypal)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-card-text>
                      </b-row>

                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('SandBox') }} : </b-td>
                    <b-td>
                      <b-row>
                        <b-form-checkbox :checked="respData['payment'] != null
                          ? respData['payment']['paypal']['sandbox']
                          : false
                          " class="custom-control-primary ml-2" name="check-button" switch
                          @change="switchChange('payment.paypal.sandbox', $event)" />
                        <b-card-text>
                          {{
                            respData["payment"] != null
                            ? respData['payment']['paypal']['sandbox'] == true
                              ? "(Sandbox โหมดทดสอบ)"
                              : "(Live โหมดใช้งานจริง)"
                            : "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-card-text>
                      </b-row>

                    </b-td>
                  </b-tr>
                  <b-tr v-if="respData['payment']['paypal']['status']">
                    <b-td>{{ $t('Public Key') }} : </b-td>
                    <b-td>
                      <b-form-input id="input-lg-omise-public-key" v-model="paypalClientKey"
                        placeholder="paypalClientKey" />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="respData['payment']['paypal']['status']">
                    <b-td>{{ $t('Secret Key') }} : </b-td>
                    <b-td>
                      <b-form-input type="password" id="input-lg-omise-secret-key" v-model="paypalSecretKey"
                        placeholder="paypalSecretKey" />
                    </b-td>
                  </b-tr>
                  <b-tr v-if="respData['payment']['paypal']['status']">
                    <b-td>{{ $t('Activate') }} : </b-td>
                    <b-td>
                      <b-card-text>
                        <b-badge :variant="respData['payment'] != null
                          ? respData['payment']['paypal']['activated'] == true
                            ? 'success'
                            : 'info'
                          : 'dark'">
                          {{
                            respData['payment']
                            != null
                            ?
                            respData['payment']['paypal']['activated'] ==
                              true
                              ?
                              "ยืนยันแล้ว"
                              :
                              "ยังไม่ยืนยัน"
                            :
                            "ยังไม่ได้ตั้งค่า"
                          }}
                        </b-badge>
                        <b-button v-if="respData['payment']['paypal']['activated'] ==
                            false" @click="activatePaypal()" class="ml-2" size="sm" variant="primary">
                          <span> {{ $t("Activate") }}</span>
                          <feather-icon icon="CheckIcon" size="12" />
                        </b-button>
                        <b-button v-if="respData['payment']['paypal']['activated'] ==
                          false" @click="confirmPaypal()" class="ml-2" size="sm" variant="success">
                          <span> {{ $t("Confirm") }}</span>
                          <feather-icon icon="CheckIcon" size="12" />
                        </b-button>
                      </b-card-text>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button size="sm" variant="primary" @click="inputChange('paypal')">
                <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
                <feather-icon icon="SaveIcon" size="12" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>
  
<script>
import { BTableSimple, BThead, BTh, BTbody, BTr, BTd } from 'bootstrap-vue'

import {
  BBadge,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/eCommerce/applicationSettings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'

const STORE_MODULE_NAME = 'applicationSettings'

export default {
  components: {
    BTableSimple, BThead, BTh, BTbody, BTr, BTd,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BBadge,
    draggable,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BListGroupItem,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logsPaypalId: ''
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    omisePublicKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.publicKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.publicKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    omiseSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.secretKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paypalClientKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paypal.clientKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'payment.paypal.clientKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paypalSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paypal.secretKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'payment.paypal.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {

      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    settingPayment(data) {
      this.$router.push({ path: `/e-commerce/application/settings/payment/${data}` })
    },
    activatePaypal() {
      store
        .dispatch(`${STORE_MODULE_NAME}/activatePaypal`, {})
        .then(result => {
          this.logsPaypalId = result.data.data.logsId
          window.open(
            result.data.data.url,
            '_blank' // <- This is what makes it open in a new window.
          );
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'bottom-right',
          //   props: {
          //     title: this.$t('Success'),
          //     icon: 'SuccessIcon',
          //     variant: 'success',
          //     text: this.$t(result.data.message),
          //   },
          // })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    confirmPaypal() {
      store
        .dispatch(`${STORE_MODULE_NAME}/confirmPaypal`, { logsId: this.logsPaypalId })
        .then(result => {
          store
            .dispatch(`${STORE_MODULE_NAME}/get`)
            .then(result => {

            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    inputChange(name) {
      const obj = {}
      console.log(name)
      if (name === 'omise') {
        obj.omise = {
          publicKey: this.omisePublicKey,
          secretKey: this.omiseSecretKey,
        }
      }
      if (name === 'paypal') {
        obj.payment = {
          paypal: {
            clientKey: this.paypalClientKey,
            secretKey: this.paypalSecretKey,
          }
        }
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {

      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  